import React from 'react';
import Avatar from 'Components/Member/Avatar';
import Listing from 'Components/Core/Listing';
import 'CSS/Member/MemberList.css';

export default function MemberListBasis(props) {

	const renderCount = React.useRef(0);
	const wa = props.wa;
	const wab = props.wa.block;
	const wah = props.wa.hint;
	const was = props.wa.system;
	const wam = props.wa.module;
	const wau = props.wa.utility;
	const active = (typeof props.active === 'undefined') ? 0 : props.active;

	let orderField = (typeof props.orderField === 'undefined') ? '' : '&order-field=' + props.orderField;
	var lf;
	var memberList;
	var onlineCount;
	// var memberCount = 0;

	React.useEffect(() => {
		renderCount.current++;
		if (renderCount.current === 1) {
			loadMemberList();
		}
	});

	wab.init(props.id, {
	});

	was.loadActiveMember = loadMemberList;
	was.makeMemberList = makeMemberList;

	function registerListing(listingFunctions) {
		lf = listingFunctions;
	}

	function loadMemberList(type = 'anonym') {
		// console.log('loadMemberList: ('+ type +') ' + new Date().toTimeString());
		const url = 'Backend/webapp.php?module=Member&task=MemberList&active=1' + ((orderField === '') ? '' : '&order-field=' + orderField);
		was.axGet(url, handleLoadMemberList);
	}

	function handleLoadMemberList(result) {
		if (result.data['returncode'] === 1) {
			// console.log(result.data.buildno);
			if (result.data.buildno > was.buildNo) {
				was.loadSoftwareUpdate();
			}
			else {
				memberList = result.data['content'];
				makeMemberList();
				clearTimeout(was.loadMemberListTimeOut);
				was.loadMemberListTimeOut = setTimeout(() => loadMemberList('Timeout'), 30000);
				addNewMessageHint();
			}
		}
		else {
			wah.addHint('Fehler beim Lesen der Member-Tabelle!', true);

		}

	}

	function addNewMessageHint() {
		if (Object.keys(was.postingsUnread).length > 1) {
			let conversationalPartner;
			Object.entries(was.postingsUnread).forEach(([key, value]) => {
				if (key !== 'forum') {
					conversationalPartner = wau.getConversationalPartner(key);
					// wah.addHint(args.hint);
					if (typeof was.postingsUnreadHint[conversationalPartner] !== 'undefined' && wah.isVisible(was.postingsUnreadHint[conversationalPartner])) {
						wah.removeHint(null, was.postingsUnreadHint[conversationalPartner]);
						was.postingsLastUnread[key] = 0;
					}
					if (typeof was.postingsLastUnread[key] === 'undefined' || was.postingsUnread[key] > was.postingsLastUnread[key]) {
						const hint = ((was.postingsUnread[key] > 1) ? 'Sie haben ' + was.postingsUnread[key] + ' neue Privatnachrichten von ' : 'Sie haben eine neue Privatnachricht von ') + was.members[conversationalPartner].name + '!';
						was.postingsUnreadHint[conversationalPartner] = wah.addHint(hint, false);
						was.postingsLastUnread[key] = was.postingsUnread[key];
					}
				}
			});
		}
	}

	function makeMemberList(updatePostingsUnread = true) {
		lf.reset();
		was.postingsUnread = { 'forum': was.postingsUnread.forum };				// Alle Member-Foren löschen 
		onlineCount = 0;
		was.members = {}
		if (memberList) {

			memberList.forEach(
				function (item) {
					if (updatePostingsUnread && item.postingsunread > 0) {
						was.postingsUnread[item.forumid] = item.postingsunread;
					}
					addRow(item);
					was.members[item.memberid] = { 
						'name': item.membername, 
						'level': item.memberlevel, 
						'avatar': item.avatartext,
						'avatarimage': item.avatarimage 
					};
					if (item.memberid === was.memberId) {
						was.avatarimage = item.avatarimage;
					}
				}
			)
		}

		addHeader();			// muss nach den Rows erzeugt werden, da in der Funktion addRow die Variable onlineCount hochgezählt wird
		// addFooter();

		lf.makeListing();
		was.updatePostingsUnread();
	}

	function sortMemberList(event, sortField) {
		event.preventDefault();
		orderField = sortField;
		loadMemberList();
	}

	function addHeader() {
		if (active) {
			let tagOnlineCount =
				<span className='online-count'>
					{'Online: ' + onlineCount}
				</span>
			// let tagHome = <i className="bi bi-house app-icon" title="Startseite anzeigen" onClick={activateHome}></i>
			// let tagSetHome = <i className="bi bi-house-check app-icon" title="Aktuelles Modul als Startseite festlegen" onClick={e => wam.activate('forum')}></i>
			let tagAvatar =
				<span onClick={e => wam.activate('settings')}>
					<Avatar memberLevel={was.memberLevel} memberName={''} avatarText={was.avatarText} image={was.avatarimage}>
					</Avatar>
				</span>;
			let header =
				<React.Fragment>
					{tagAvatar} 
					{tagOnlineCount}
					<i className="bi bi-box-arrow-right app-icon" title="Abmelden" onClick={e => was.logout()}></i>
				</React.Fragment>
			lf.addHeader(<span>{header}</span>);
		}
		else {
			lf.addHeader(<span className="anker" onClick={(e) => { sortMemberList(e, 'memberid') }}>{'ID'}</span>);
			lf.addHeader((<span className="anker" onClick={(e) => { sortMemberList(e, 'membername') }}>{'Pseudonym'}</span>));
			lf.addHeader((<span className="anker" onClick={(e) => { sortMemberList(e, 'm.lastlogin desc') }}>{'Letzte Anmeldung'}</span>));
			lf.addHeader(<span className="anker" onClick={(e) => { sortMemberList(e, 'm.lastrequest desc') }}>{'Letzter Zugriff'}</span>);
			lf.addHeader(<span className="anker" onClick={(e) => { sortMemberList(e, 'locked desc') }}>{'Gesperrt'}</span>);
			lf.addHeader(<span className="anker" onClick={(e) => { sortMemberList(e, 'postingcount desc') }}>{'Beiträge'}</span>);
		}
	}

	function rowClicked(e, raw) {
		e.preventDefault();
		if (wau.getAttribute(e, 'column-id', '') === 'avatar') {
			wam.openProfile(raw.memberid, raw.membername, raw.memberlevel)	
		}
		else {
			wam.openPrivateForum(raw.memberid, raw.membername, raw.memberlevel);
		}
	}

	function addRow(item) {
		if (active) {
			onlineCount = onlineCount + ((item.online === 1) ? 1 : 0);
			let onlineIconClass = (item.online === 1) ? 'bi-circle-fill' : 'bi-circle';
			//			tagAvatar = <Avatar memberLevel={item.memberlevel} avatarText={item.avatartext} small />;
			let tagMemberName =
				<span className='avatar-list-name' title={'ID: ' + item.memberid}>
					<i className={` bi ${onlineIconClass} `} title="Online-Status" />
					<span className="member-name">{item.membername}</span>
				</span>
			let tagAvatar =
				<React.Fragment>
					<Avatar memberLevel={item.memberlevel} memberName={tagMemberName} avatarText={item.avatartext} size="small" image={item.avatarimage} />
				</React.Fragment>;
			const tagForum =
				<React.Fragment>
					<i className="bi bi-chat-square-text-fill no-user-forum-icon"></i>
					<span className="forum-name">{item.membername}</span>
				</React.Fragment>;
			let tagUnread =
				(item.forumid === 'forum') 
					?
					<React.Fragment>
						<span className={` unread-count ${(was.postingsUnread.forum === 0) ? "d-none" : ""} `} >{(was.postingsUnread.forum > 999) ? '++' : was.postingsUnread.forum}</span>
					</React.Fragment>
					:
					<React.Fragment>
						<span className={` unread-count ${(item.postingsunread === 0) ? "d-none" : ""} `} >{(item.postingsunread > 999) ? '++' : item.postingsunread}</span>
					</React.Fragment>

			let tagRow =
				<React.Fragment>
					{(item.ismember === 1) ? tagAvatar : tagForum}
					{tagUnread}
				</React.Fragment>;

			lf.addRow(
				[tagRow], 
				item,
				(item.ismember === 1) ? '' : 'listing-row-no-user-forum',
				(e, raw, column) => {
					e.preventDefault();
					if (raw.forumid === 'forum' || raw.forumid === 'admin') {
						wam.activate(raw.forumid);
					}
					else {
						rowClicked(e, raw);
						// was.openPrivateForum(raw.memberid, raw.membername, raw.memberlevel);
					}
				}
			);

		}
		else {
			if (item.ismember === 1) {
				let tagAvatar = 
					<Avatar 
						columnId="avatar"
						memberLevel={item.memberlevel} 
						memberName={item.membername} 
						avatarText={item.avatartext} 
						size="small" 
						image={item.avatarimage}
					/>;
				let tagLocked = (item.locked === 1) ? <i className="bi bi-check-circle"></i> : null;
				lf.addRow([
					item.memberid, 
					tagAvatar, 
					item.lastlogin, 
					item.lastrequest, 
					tagLocked, 
					item.postingcount], 
					item,
					'',
					(e, raw, column) => {
						rowClicked(e, raw);
					}
				);
			}
		}
	}

	return (
		<Listing id="member-listing" wa={wa} registerListing={registerListing} classes={`member-list ${props.classes}`} noTitle={false} />
	)


};

