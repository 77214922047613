import React from "react";
import FormDatePicker from "Components/Core/Form/FormDatePicker";
import 'CSS/Forum/PostingDay.css';

export default function PostingDay(props) {
	const waf = props.wa.form;
	const wam = props.wa.module;

	var wafId = props.dialogId;
	waf.init(wafId);


	function dateSelected(date) {
		wam[props.moduleId].setTab('archive');
		wam[props.moduleId].archive.setSelectDate(date);
		wam[props.moduleId].archive.load();
	}

	return (
		<div className="div-posting-day">
			<FormDatePicker dateSelected={dateSelected}/>
		</div>
	);


}
