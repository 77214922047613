import React from 'react';
import Module from 'Components/Core/Module';
import Listing from 'Components/Core/Listing';
import FormElement from 'Components/Core/Form/FormElement';
import DialogForm from 'Components/Core/DialogForm';
import 'CSS/Forum/Smilies.css';

export default function Smilies(props) {

	const renderCount = React.useRef(1);
	const wa = props.wa;
	const wah = props.wa.hint;
	const was = props.wa.system;
	const wad = props.wa.dialog;
	const waf = props.wa.form;
	const wafId = props.id;
	const dialogNewSmileyId = "add-smiley";

	const [disabled, setDisabled] = React.useState(false);


	var lf;

	wa.module.init(props.id, {
		"onClose": () => {
			delete waf[wafId];
		}
	})

	waf.init(wafId, {
		'onAfterSend': loadSmilies
	})

	React.useEffect(() => {
		if (renderCount.current === 1) {
			loadSmilies();
		}
		renderCount.current++;
		if (was.memberLevel > 2) {
			waf.disable(wafId, 'code');
			waf.disable(wafId, 'image');
		}
	});

	function registerListing(listingFunctions) {
		lf = listingFunctions;
	}

	function loadSmilies() {
		was.axGet('Backend/webapp.php?module=Posting&task=SmileyList&active=1', handleLoadSmilies);
	}

	function handleLoadSmilies(result) {
		if (result.data['returncode'] === 1) {
			makeSmilies(result.data['content']);
			// setTimeout(loadsmilies, 30000);
		}
		else {
			wah.addHint('Fehler beim Lesen der Smilies-Tabelle!', true);
		}

	}

	function makeSmilies(smilies) {
		lf.reset();

		addHeader();

		if (smilies) {
			// memberCount = smilies.length;
			smilies.forEach(
				function (item) {

					// console.log('Online Count: ' + onlineCount);
					addRow(item);
				}
			)
		}

		lf.makeListing();
	}

	function addSmiley() {
		waf.set(wafId, 'code', '');
		waf.set(wafId, 'image', '');
		waf.hide(wafId, 'message');
		wad[dialogNewSmileyId].show(() => { waf.focus(wafId, 'code') });
	}

	function editSmiley(code, image) {
		waf.set(wafId, 'code', code);
		waf.set(wafId, 'image', image);
		waf.hide(wafId, 'message');
		setDisabled(disabled);
		wad[dialogNewSmileyId].show(() => { waf.focus(wafId, 'code') });
	}

	function addHeader() {
		lf.addHeader('Smiley');
		lf.addHeader('Kürzel');
		lf.addHeader('Unicode');
	}

	function addRow(item) {
		lf.addRow(
			[makeSmiley(item.image, item.unicode), item.code, item.image], 
			item, 
			(item.disabled===1) ? 'row-disabled' : '', 
			(e, itemClicked, itemNoClicked) => {
				editSmiley(itemClicked.code, itemClicked.image);
			}
		);
	}

	function makeSmiley(image, unicode) {
		const smiley = (unicode === 0) 
		? <img className="smiley" alt="Smiley" src={"Backend/webapp.php?module=Public&task=Image&file=" + image}/>
		: <i className="unicode-smiley" >{String.fromCodePoint(parseInt(image))}</i>;
		return smiley;
	}
	function remove(code) {
		was.axPost("Backend/webapp.php?module=Posting&task=DeleteSmiley&", { "code": code }, (result) => {
			// console.log(result.data['returncode']);
			if (result.data['returncode'] === 1) {
				loadSmilies();
				wad[dialogNewSmileyId].hide();
			}
			else {
				waf.set(wafId, 'message', 'Löschen des Smileys nicht möglich!');
				waf.show(wafId, 'message');
			}
		})
	}

	const moduleToolbar =
		(was.memberLevel < 3)
			?
			<span className={` bi bi-plus-square module-icon  `} title="Neues Smiley hinzufügen" onClick={() => addSmiley()}>
			</span>
			: null;

	const formFields =
		<React.Fragment>
			<FormElement type="text"
				wa={props.wa}
				dialogId={dialogNewSmileyId}
				wafId={wafId}
				id="code"
				mandatory={true}
				title="Kürzel"
				fieldValue=""
				maxLength="20"
			/>
			<FormElement type="text"
				wa={props.wa}
				dialogId={dialogNewSmileyId}
				wafId={wafId}
				id="image"
				mandatory={true}
				title="Unicode (Dezimalwert) oder Dateiname"
				fieldValue=""
				maxLength="7"
			/>
		</React.Fragment>

	const buttons = [];
	if (was.memberLevel === 1) {
		buttons.push(
			<button 
				type="button" 
				key="delete" 
				className={`btn ${(disabled) ? 'd-none' : ''}`} 
				onClick={() => {remove(waf.get(wafId, 'code'))} } 
			>
				Löschen
			</button>);
	}

	return (
		<Module
			id={props.id}
			wa={wa}
			classes={`module-smilies `}
			title={props.title}
			display="d-block"
			titleToolbar={
				<span>
					{moduleToolbar}
				</span>
			}
		>
			<Listing id="smilies-listing" wa={wa} registerListing={registerListing} classes="listing smiley-list" noTitle={false} />
			<DialogForm
				id={dialogNewSmileyId}
				wa={wa}
				wafId={wafId}
				title="Smiley bearbeiten"
				classes="dialog-new-smiley"
				children={formFields}
				module="Posting"
				sendTask="SaveSmiley"
				sendButton={(was.memberLevel < 3) ? true : false}
				buttons={buttons}
			/>
		</Module>
	)


};

