import React from "react";
import Dialog from "Components/Core/Dialog";
import Form from 'Components/Core/Form/Form';
import FormElement from 'Components/Core/Form/FormElement';
import 'CSS/Forum/DialogNewImage.css';


export default function DialogNewImage(props) {
	const wad = props.wa.dialog;
	const waf = props.wa.form;
	const was = props.wa.system;

	const image = React.useRef(null);
	
	var wafId = props.dialogId;
	waf.init(wafId);

	React.useEffect(() => {
		wad[props.id].setImage = setImage;
		wad[props.id].activate = activate;
		waf[wafId].ok = upload;
	});

	function setImage(newImage) {
		image.current = newImage;
	}

	function activate() {
		waf.setSource(props.moduleId + '-new-image', props.moduleId + '-image-from-clipboard', URL.createObjectURL(image.current));
		wad[props.moduleId + '-new-image'].show();
	}

	function upload(event) {
		const formData = new FormData();
		formData.append('image', image.current, 'image.png');
		was.axPost('Backend/webapp.php?module=Posting&task=ImageFromClipboard', formData, (result) => {
			if (result.data.returncode === 1) {
				wad[props.moduleId + '-new-image'].hide();
				const newThreadID = props.moduleId + '-new-thread';
				const answerID = props.moduleId + '-answer';
				// IDs überprüfen!!! 
				if (!wad[newThreadID].isVisible && !wad[answerID].isVisible ) {
					wad[newThreadID].show(() => { waf.focus(newThreadID, 'posting') });
				}
				if (wad[answerID].isVisible ) {
					addLink(answerID, result.data.content.fileName);
				}
				else {
					addLink(newThreadID, result.data.content.fileName);
				}
			}
			else {
				console.log('Image Upload Error!' );
			}
		})
	} 

	function addLink(wafId, fileName) {
		const url = '\r\n[img]Backend/image.php?file=' + fileName + '[/img]\r\n';
		const curPos = waf[wafId].elements['posting'].reference.current.selectionStart;
		const curVal = waf.get(wafId,'posting');
		const leftValue = curVal.substring(0, curPos);
		const rightValue = curVal.substring(curPos);
		// waf.set(wafId,'posting',leftValue + '[url=' + url + ']' + url + '[/url]' + rightValue);
		waf.set(wafId,'posting',leftValue + url + rightValue);
		waf.setCursorPos(wafId, 'posting', 0, 0);
		waf.focus(wafId, 'posting');
	}


	return (
		<Dialog id={props.id} wa = {props.wa} title="Bild aus Zwischenablage importieren" classes="dialog-new-image ">
				<Form 	wa={props.wa} 
						wafId={wafId} 
						enableSendButton={true}
						sendButtonTitle="Datei hochladen"
						focusElement="name" 
						classes="dialog-new-image-form">	
						<div className="div-new-image">
							<FormElement type="image" wa={props.wa} wafId={wafId} id={props.moduleId + '-image-from-clipboard'} />
						</div>
						<label id="login-error" >Bitte Bildrechte und Lizenzen beachten!</label>
				</Form>				
		</Dialog>

	);


}
