import React from 'react';
import Module from 'Components/Core/Module';
import Listing from 'Components/Core/Listing';
import FormElement from 'Components/Core/Form/FormElement';
import DialogForm from 'Components/Core/DialogForm';
import 'CSS/Forum/Topics.css';

export default function Topics(props) {

	const renderCount = React.useRef(1);
	const wa = props.wa;
	const wah = props.wa.hint;
	const was = props.wa.system;
	const wad = props.wa.dialog;
	const waf = props.wa.form;
	const wafId = props.id;
	const dialogNewTopicId = "add-topic";

	const [disabled, setDisabled] = React.useState(false);
	const topicId = React.useRef(0);


	var lf;

	wa.module.init(props.id, {
		"onClose": () => {
			delete waf[wafId];
		}
	})

	waf.init(wafId, {
		'onAfterSend': loadTopics
	})

	React.useEffect(() => {
		if (renderCount.current === 1) {
			loadTopics();
		}
		renderCount.current++;
		if (was.memberLevel > 2) {
			waf.disable(wafId, 'name');
			waf.disable(wafId, 'description');
		}
	});

	function registerListing(listingFunctions) {
		lf = listingFunctions;
	}

	function loadTopics() {
		was.axGet('Backend/webapp.php?module=Posting&task=TopicList&active=1', handleLoadTopics);
	}

	function handleLoadTopics(result) {
		if (result.data['returncode'] === 1) {
			makeTopics(result.data['content']);
			// setTimeout(loadTopics, 30000);
		}
		else {
			wah.addHint('Fehler beim Lesen der Topics-Tabelle!', true);
		}

	}

	function makeTopics(topics) {
		lf.reset();

		addHeader();

		if (topics) {
			// memberCount = topics.length;
			topics.forEach(
				function (item) {

					// console.log('Online Count: ' + onlineCount);
					addRow(item);
				}
			)
		}

		lf.makeListing();
	}

	function addTopic() {
		waf.setAddSend(wafId, 'topicid', 0);
		waf.set(wafId, 'name', '');
		waf.set(wafId, 'description', '');
		waf.hide(wafId, 'message');
		wad[dialogNewTopicId].show();
	}

	function editTopic(id, name, description, disabled) {
		waf.setAddSend(wafId, 'topicid', id);
		waf.set(wafId, 'name', name);
		waf.set(wafId, 'description', description);
		waf.hide(wafId, 'message');
		topicId.current = id;
		setDisabled(disabled);
		wad[dialogNewTopicId].show(() => { waf.focus(wafId, 'name') });
	}

	function addHeader() {
		lf.addHeader('Thema');
		lf.addHeader('Beschreibung');
		lf.addHeader('Deaktiviert');
	}

	function addRow(item) {
		let tagDisabled = (item.disabled === 1) ? <i className="bi bi-check-circle"></i> : null;
		lf.addRow(
			[item.name, item.description, tagDisabled], 
			{"topicId": item.topicid, "name":item.name, "description":item.description, "disabled":item.disabled}, 
			(item.disabled===1) ? 'row-disabled' : '', 
			(e, itemClicked, itemNoClicked) => {
				editTopic(itemClicked.topicId, itemClicked.name, itemClicked.description, itemClicked.disabled)
			}
		);
	}

	function enable() {
		was.axPost("Backend/webapp.php?module=Posting&task=SetDisableTopic&", { "topicid": topicId.current, "disabled": 0 }, (result) => {
			console.log(result.data['returncode']);
			if (result.data['returncode'] === 1) {
				loadTopics();
				wad[dialogNewTopicId].hide();
			}
		})
	}

	function disable() {
		was.axPost("Backend/webapp.php?module=Posting&task=SetDisableTopic&", { "topicid": topicId.current, "disabled": 1 }, (result) => {
			console.log(result.data['returncode']);
			if (result.data['returncode'] === 1) {
				loadTopics();
				wad[dialogNewTopicId].hide();
			}
		})
	}

	function remove() {
		was.axPost("Backend/webapp.php?module=Posting&task=DeleteTopic&", { "topicid": topicId.current, "disabled": 1 }, (result) => {
			console.log(result.data['returncode']);
			if (result.data['returncode'] === 1) {
				loadTopics();
				wad[dialogNewTopicId].hide();
			}
			else {
				waf.set(wafId, 'message', 'Löschen des Themas nicht möglich!');
				waf.show(wafId, 'message');
			}
		})
	}

	const moduleToolbar =
		(was.memberLevel < 3)
			?
			<span className={` bi bi-plus-square module-icon  `} title="Neues Thema anlegen" onClick={() => addTopic()}>
			</span>
			: null;

	const formFields =
		<React.Fragment>
			<FormElement type="text"
				wa={props.wa}
				dialogId={dialogNewTopicId}
				wafId={wafId}
				id="name"
				mandatory={true}
				title="Thema"
				fieldValue=""
				maxLength="20"
			/>
			<FormElement type="textarea"
				wa={props.wa}
				dialogId={dialogNewTopicId}
				wafId={wafId}
				id="description"
				mandatory={true}
				title="Beschreibung"
				fieldValue=""
				maxLength="200000"
			/>
		</React.Fragment>

	const buttons = [];
	if (was.memberLevel < 3) {
		buttons.push(<button type="button" key="enable" className={`btn ${(disabled) ? '' : 'd-none'}`} onClick={enable} >Aktivieren</button>);
		buttons.push(<button type="button" key="disable" className={`btn ${(disabled) ? 'd-none' : ''}`} onClick={disable} >Deaktivieren</button>);
	}
	if (was.memberLevel === 1) {
		buttons.push(<button type="button" key="delete" className={`btn ${(disabled) ? 'd-none' : ''}`} onClick={remove} >Löschen</button>);
	}

	return (
		<Module
			id={props.id}
			wa={wa}
			classes={`module-topics `}
			title={props.title}
			display="d-block"
			titleToolbar={
				<span>
					{moduleToolbar}
				</span>
			}
		>
			<Listing id="topics-listing" wa={wa} registerListing={registerListing} classes="listing topic-list" noTitle={false} />
			<DialogForm
				id={dialogNewTopicId}
				wa={wa}
				wafId={wafId}
				title="Thema bearbeiten"
				classes="dialog-new-topic"
				children={formFields}
				module="Posting"
				sendTask="SaveTopic"
				sendButton={(was.memberLevel < 3) ? true : false}
				buttons={buttons}
			/>
		</Module>
	)


};

