import React from 'react';
import axios from "axios";

import WebappBlock from 'Components/Webapp/WebappBlock';
import WebappDialog from 'Components/Webapp/WebappDialog';
import WebappForm from 'Components/Webapp/WebappForm';
import WebappHint from 'Components/Webapp/WebappHint';
import WebappLayout from 'Components/Webapp/WebappLayout';
import WebappModule from 'Components/Webapp/WebappModule';
import WebappNavigation from 'Components/Webapp/WebappNavigation';
import WebappSystem from 'Components/Webapp/WebappSystem';
import WebappUtility from 'Components/Webapp/WebappUtility';
import WebappWebSocket from 'Components/Webapp/WebappWebSocket';
import DialogOk from 'Components/Dialog/DialogOk';
import DialogYesNo from 'Components/Dialog/DialogYesNo';
import HintToMembers from 'Components/App/HintToMembers';
import Modal from 'Components/Core/Modal';
import NavigationTop from 'Components/App/NavigationTop';
// import NavigationBottom from 'Components/App/NavigationBottom';
import NavigationLeft from 'Components/App/NavigationLeft';
import MemberList from 'Components/Member/MemberList';
import Topics from 'Components/Forum/Topics';
import Smilies from 'Components/Forum/Smilies';
import Forum from 'Components/Forum/Forum';
import Settings from 'Components/Member/Settings';
import PhpInfo from 'Components/Admin/PhpInfo';
import Login from 'Components/Member/Login';
import SystemInfo from 'Components/App/SystemInfo';

export default function Webapp(props) {

	const renderChildCount = React.useRef(0);
	const initData = React.useRef(null);

	const [block, setBlock] = React.useState([]);
	const [dialog, setDialog] = React.useState([]);
	const [hint, setHint] = React.useState([]);
	const [module, setModule] = React.useState([]);
	const [navigation, setNavigation] = React.useState([]);
	const [webapp, setWebapp] = React.useState([]);
	const [style, setStyle] = React.useState({});

	const [renderForum, setRenderForum] = React.useState(Date.now());

	const wa = React.useRef({});
	window.webapp = wa.current;
	wa.current.block = (typeof wa.current.block === 'undefined') ? {} : wa.current.block;
	wa.current.block.tags = (typeof wa.current.block.tags === 'undefined') ? [] : wa.current.block.tags;
	wa.current.dialog = (typeof wa.current.dialog === 'undefined') ? {} : wa.current.dialog;
	wa.current.dialog.tags = (typeof wa.current.dialog.tags === 'undefined') ? [] : wa.current.dialog.tags;
	wa.current.form = (typeof wa.current.form === 'undefined') ? {} : wa.current.form;
	wa.current.layout = (typeof wa.current.layout === 'undefined') ? {} : wa.current.layout;
	wa.current.hint = (typeof wa.current.hint === 'undefined') ? {} : wa.current.hint;
	wa.current.module = (typeof wa.current.module === 'undefined') ? {} : wa.current.module;
	wa.current.navigation = (typeof wa.current.navigation === 'undefined') ? {} : wa.current.navigation;
	wa.current.system = (typeof wa.current.system === 'undefined') ? {} : wa.current.system;
	wa.current.utility = (typeof wa.current.utility === 'undefined') ? {} : wa.current.utility;
	wa.current.webSocket = (typeof wa.current.webSocket === 'undefined') ? {} : wa.current.webSocket;

	const wab = wa.current.block;
	const wah = wa.current.hint;
	const wal = wa.current.layout;
	const wam = wa.current.module;
	const was = wa.current.system;
	const $ = window.$;
	const renderCount = React.useRef(1);
	let imageFullSize = null;

	wal.style = (typeof wal.style === 'undefined') ? {} : wal.style;

	React.useEffect(() => {
		document.addEventListener("keyup", onKey);
		document.addEventListener("click", onClick);
		if (renderCount.current === 1) {
			const getInit = async () => {
				const token = localStorage.getItem("token");
				let url = '/Backend/webapp.php?module=Public&task=Init&token=' + ((token === null) ? '0' : token);
				initData.current = await axios.get(url);
				// console.log(initData.current.data.content.smilies);
				createWebapp();
			};
			getInit();

			renderCount.current++;
		}
		return () => {
			document.removeEventListener("keyup", onKey);
		}
	});

	function createWebapp() {
		addWebapp(<WebappLayout id="webapp-layout" wa={wa.current} childRendered={childRendered} />);
		addWebapp(<WebappUtility id="webapp-utility" wa={wa.current} childRendered={childRendered} />);
		addWebapp(<WebappSystem id="webapp-system" wa={wa.current} childRendered={childRendered} />);
		addWebapp(<WebappNavigation id="webapp-navigation" wa={wa.current} childRendered={childRendered} />);
		addWebapp(<WebappModule id="webapp-module" wa={wa.current} childRendered={childRendered} />);
		addWebapp(<WebappWebSocket id="webapp-socket" wa={wa.current} childRendered={childRendered} />);
		addWebapp(<WebappBlock id="webapp-block" wa={wa.current} childRendered={childRendered} />);
		addWebapp(<WebappDialog id="webapp-dialog" wa={wa.current} childRendered={childRendered} />);
		addWebapp(<WebappForm id="webapp-form" wa={wa.current} childRendered={childRendered} />);
		addWebapp(<WebappHint id="webapp-hint" wa={wa.current} childRendered={childRendered} />);
	}

	function childRendered(id = '') {
		renderChildCount.current++;
		if (id === 'webapp-layout') {
			setStyle(wal.style);
		}
		if (renderChildCount.current === 10) {
			webappRendered();
		}
	}


	function webappRendered() {

		const result = initData.current;
		was.webapp = 1;

		if (result.data['returncode'] === 1) {
			if (document.body.clientWidth < 350) {
				// window.location.href = 'bad-device.html';
			}
			// registriere globale Funktionen
			was.activateNavigation = activateNavigation;
			was.addHint = addHint;
			was.addModule = addModule;
			was.addNavigation = addNavigation;
			was.removeDialog = removeDialog;
			was.removeHint = removeHint;
			was.removeModule = removeModule;
			was.renderForum = updateForum;
			// console.log(token);
			// System-Variable setzen
			was.buildNo = result.data.content.labels.buildno;
			was.setSmilies(result.data.content.smilies);
			was.memberId = result.data.content.memberid;
			was.memberName = result.data.content.membername;
			was.avatarText = result.data.content.avatartext;
			was.memberLevel = result.data.content.memberlevel;
			was.forumScroll = (result.data.content.forumscroll);
			was.forumHideImages = result.data.content.forumhideimages;
			was.forumHideContent = result.data.content.forumhidecontent;
			was.forumHideDiscussion = result.data.content.forumhidediscussion;
			was.forumHideAvatar = result.data.content.forumhideavatar;
			was.forumHideDate = result.data.content.forumhidedate;
			was.forumMemberColored = result.data.content.forummembercolored;
			was.forumFontBold = result.data.content.forumfontbold;
			was.forumSendWithEnter = result.data.content.forumsendwithenter;
			was.darkTheme = result.data.content.darktheme;
			was.darkThemeVariant = result.data.content.darkthemevariant;
			was.webAppLoaded = webAppLoaded;
			wal.setStyle();
			setStyle(wal.style);
			// console.log('Build No: ' + was.buildNo);
			// was.updateUnread(JSON.parse(result.data.content.unread));
			setRenderForum(Date.now());
			was.postingsUnread = JSON.parse(result.data.content.unread);
			init(wa.current.system);
			setInterval(() => {
				// was.loadActiveMember();
				// console.log('Set Login Status');
			}, 60000);

			// Erzeuge Blocks
			addBlock(wab.tags['modal']);
			addBlock(wab.tags['navigation-top']);
			// addBlock(wab.tags['active-member-list']);
			addBlock(wab.tags['navigation-left']);
			// addBlock(wab.tags['navigation-bottom']);

			// Erzeuge Dialoge
			addDialog(wa.current.dialog.tags['ok']);
			addDialog(wa.current.dialog.tags['yesno']);
			// addDialog(wa.current.dialog.tags['dialog-form']);

			// Erzeuge Module
			if (was.isAdmin()) addModule(wa.current.module.tags['admin']);
			addModule(wa.current.module.tags['forum']);

			// Erzeuge Navigation
			//addNavigation(<OpenWindows id="open-windows" display="d-none" wa={wa.current} />);

			was.application = result.data.content.labels.application;
			document.title = was.application;
			// wa.current.module.activate('forum');

			// setTheme('light');

		}
		else {
			was.application = result.data.content.labels.application;
			was.privateMessage = result.data.content.labels.privatemessage;
			addModule(<Login id="login" wa={wa.current} formId="login" title="Login" />)
		}
	};

	function webAppLoaded() {
		if (localStorage.getItem('software-update') !== null) {
			wah.addHint('Die Software wurde aktualisiert...', false);
			localStorage.removeItem('software-update');
		}
	}


	function init(was) {
		// Block-Definition
		wa.current.block.addTag(<Modal id="modal" wa={wa.current} />);
		wa.current.block.addTag(<NavigationTop id="navigation-top" forum={true} wa={wa.current} />);
		// wa.current.block.addTag(<NavigationBottom id="navigation-bottom" wa={wa.current} />);
		wa.current.block.addTag(<NavigationLeft id="navigation-left" wa={wa.current} />);
		// wa.current.block.addTag(<ActiveMemberList id="active-member-list" wa={wa.current} />);

		/*
		// Navigation-Definition

		wan.add(<OpenWindows id="open-windows" display="d-none" wf={wf} />);
		// Die Menüs werden dynamisch in der Menu.js Komponente erzeugt
		*/

		// Dialog-Definition

		wa.current.dialog.addTag(<DialogOk id="ok" wa={wa.current} />);
		wa.current.dialog.addTag(<DialogYesNo id="yesno" wa={wa.current} />);

		// Modul-Definition

		wa.current.module.addTag(<Forum id="forum" forumId="forum" wa={wa.current} forum={true} title={was.application} display="d-none" />);
		wa.current.module.addTag(<Forum id="admin" forumId="admin" wa={wa.current} forum={true} title={'Admin-Forum'} display="d-none" />);
		// wa.current.module.addTag(<Test id="test" wa={wa.current} title="Test" />);
		// wa.current.module.addTag(<Forum id="forum-admin" wa={wa.current} title="Admin-Forum" display="d-none" />);
		wa.current.module.addTag(<Smilies id="smilies" wa={wa.current} title="Smilies" display="d-box" />);
		wa.current.module.addTag(<Topics id="topics" wa={wa.current} title="Themen" display="d-box" />);
		wa.current.module.addTag(<MemberList id="member-list" wa={wa.current} active={false} title="Mitgliederliste" display="d-box" />);
		wa.current.module.addTag(<SystemInfo id="system-info" wa={wa.current} title="System-Information" display="d-box" />);
		wa.current.module.addTag(<HintToMembers id="hint-to-members" wa={wa.current} title="Nachricht an alle" display="d-box" />);
		wa.current.module.addTag(<Settings id="settings" wa={wa.current} title="Einstellungen" display="d-box" />);
		wa.current.module.addTag(<PhpInfo id="php-info" wa={wa.current} title="PHP-Info" display="d-box" />);

		// Clipboard
		document.addEventListener('paste', function (evt) {
			// Get the data of clipboard
			const clipboardItems = evt.clipboardData.items;
			const items = [].slice.call(clipboardItems).filter(function (item) {
				// Filter the image items only
				return item.type.indexOf('image') !== -1;
			});
			if (items.length > 0) {
				const currentModuleId = wa.current.module.getForumId();
				wa.current.module.activate(currentModuleId);
				const item = items[0];
				const image = item.getAsFile();
				const newImageId = currentModuleId + '-new-image';
				wa.current.dialog[newImageId].setImage(image);
				wa.current.dialog[newImageId].activate();
			}
		});

	}

	function updateForum() {
		setRenderForum(Date.now());
	}

	function addBlock(obj) {
		obj = { tag: obj };
		setBlock(current => [...current, obj]);
	};

	function addDialog(obj) {
		wa.current.dialog.init(obj.props.id);
		obj = { tag: obj };
		setDialog(current => [...current, obj]);
	};

	function addHint(obj) {
		obj = { tag: obj };
		setHint(current => [...current, obj]);
	};

	const addModule = (obj) => {
		wam.init(obj.props.id);
		wam.current = obj.props.id;
		obj = { tag: obj };
		setModule(current => [...current, obj]);
	};

	const addNavigation = (obj) => {
		obj = { tag: obj };
		setNavigation(current => [...current, obj]);
	};

	const addWebapp = (obj) => {
		obj = { tag: obj };
		setWebapp(current => [...current, obj]);
	};

	function removeDialog(id) {
		delete wa.current.dialog[id];
		setDialog(prev => prev.filter(moduleTag => moduleTag.tag.props.id !== id))
	}

	function removeHint(id) {
		delete wa.current.hint[id];
		setHint(prev => prev.filter(hintTag => hintTag.tag.props.id !== id))
	}

	function removeModule(id) {
		wa.current.module[id].onClose();
		delete wa.current.module[id];
		setModule(prev => prev.filter(moduleTag => moduleTag.tag.props.id !== id))
	}

	function activateNavigation(id) {
		const wan = wa.current.navigation;
		wan.hideAll();
		wan[id].init();
		wan[id].show();
	}

	function onKey(event) {
		wa.current.system.onKey(event);
	}
	function onClick(event) {
		wa.current.system.onClick(event);
	}

	function globalClick(event) {
		if (event.target.tagName === 'IMG' && !$(event.target).hasClass('smiley') && wa.current.module.isForum()) {
			checkImageClick(event.target);
			imageFullSize = event.target;
			if (!$(event.target).hasClass('img-full-size')) {
				$(imageFullSize).addClass('img-full-size');
			}
			else {
				$(imageFullSize).removeClass('img-full-size');
			}
		}
		else {
			$(imageFullSize).removeClass('img-full-size');
		}
	}

	function checkImageClick(target) {
		if (target !== imageFullSize) {
			if (imageFullSize) {
				$(imageFullSize).removeClass('img-full-size');
			}
		}
	}

	return (
		<div id="webapp" key="webapp" style={style}>
			{webapp.map(({ tag, id }) =>
				<React.Fragment key={tag.props.id}> {tag}</React.Fragment>
			)}
			{block.map(({ tag, id }) =>
				<React.Fragment key={"block-" + tag.props.id}> 
						{(tag.props.forum === true) ? React.cloneElement(tag, {updateForum: renderForum}) : tag}
				</React.Fragment>
			)}
			{dialog.map(({ tag, id }) =>
				<React.Fragment key={"dialog-" + tag.props.id}> {tag}</React.Fragment>
			)}
			<div key="hint" id="hint" className="hint-frame" >
				{hint.map(({ tag, id }) =>
					<React.Fragment key={"hint-" + tag.props.id}> {tag}</React.Fragment>
				)}
			</div>
			{navigation.map(({ tag, id }) =>
				<React.Fragment key={"navigation-" + tag.props.id}> {tag}</React.Fragment>
			)}
			<div key="module" id="module" onClick={e => globalClick(e)} onScroll={e => globalClick(e)} className="flex-fill overflow-hidden" >
				{module.map(({ tag, id }) =>
					<React.Fragment key={"module-" + tag.props.id}>
						{(tag.props.forum === true) ? React.cloneElement(tag, {updateForum: renderForum}) : tag}
					</React.Fragment>

				)}

			</div>
		</div>
	)

}