import React from 'react';
import 'CSS/Member/Avatar.css';

export default function Avatar(props) {
	const avatarText = (props.avatarText !== '') ? props.avatarText.toUpperCase() : props.avatarText;
	const columnId = (typeof props.columnId === 'undefined') ? 'avatar' : props.columnId; 
	const title = (typeof props.title === 'undefined') ? '' : props.title; 
	const size = (typeof props.size === 'undefined') ? 'large' : props.size; 
	const image = (typeof props.image === 'undefined') ? '' : props.image; 
	const onClick = (typeof props.onClick === 'undefined') ? () => {} : props.onClick; 
    let avatarColor = 'bg-primary';
    let avatarClass, avatarContentClass;
	let avatarTag = <></>;
	let memberTag = <></>;
    
	avatarClass = 'webapp-avatar-' + size;
	avatarContentClass = 'webapp-avatar-content-' + size;

	if (typeof props.memberName !== 'undefined') {
		memberTag = <span column-id={columnId + '-name'} className='avatar-name'>{props.memberName}</span>
    }


	avatarTag = (image !== '') 
		? 
			<img column-id={columnId} alt="Avatar" className='avatar-image' src={'Backend/webapp.php?module=Public&task=Image&file=avatar/' + image + '.png'} /> 
		:
			<span column-id={columnId} className='avatar-text'>{avatarText}</span>;

	if (image !== '') {
		avatarColor = 'bg-transparent';
	}
	else {
		switch (parseInt(props.memberLevel)) {
			case 1:
			avatarColor = 'bg-primary';
			break;
			case 2:
			avatarColor = 'bg-success';
			break;
			default:
			avatarColor = 'bg-orange';
		}
    }

    return (
		<div className={`webapp-avatar ${avatarClass}`}>            
			<span 
				onClick={(e) => onClick(e, true)} 
				className={`rounded text-light webapp-avatar-content ${avatarContentClass} ${avatarColor}`}
				title={title}
			>
				{avatarTag}
			</span>
			<span onClick={(e) => onClick(e,false)}>
				{memberTag}
			</span>
			{props.children}
		</div>
    );

}
