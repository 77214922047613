import React from 'react';
import FormElement from 'Components/Core/Form/FormElement';
import 'CSS/Forum/DialogAddBBCode.css';
import DialogAddBBCode from './DialogAddBBCode';

export default function DialogAddText(props) {

	const waf = props.wa.form;
	const type = (typeof props.type === 'undefined') ? 'text' : props.type; 

	return (

		<DialogAddBBCode 
			id={props.id} 
			wa={props.wa} 
			title={props.title} 
			tag={props.tag} 
			focus={() => {waf.setFocus(props.id, 'text')}}
			reset={() => {	
				waf.set(props.id, 'text', '');
			}}
			answerTo={props.answerTo} 
		 >
			<FormElement type={type} wa={props.wa} wafId={props.id} id="text" title="Text" />
		</DialogAddBBCode>

	)
};
