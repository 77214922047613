import React from 'react';
import Block from 'Components/Core/Block';
import Menu from 'Components/App/Menu';
import NewThreadShort from 'Components/Forum/NewThreadShort';
import axios from "axios";
import 'CSS/App/NavigationTop.css';

export default function NavigationTop(props) {

	let menuSource = '';                // Enthält die Menü-Daten vom Server. Diese werden dann in der Funktion makeMenu aufbereitet. 
	let newPostingClass = 'd-block';
	const wam = props.wa.module;
	const waf = props.wa.form;
	const wan = props.wa.navigation;
	const was = props.wa.system;
	const wau = props.wa.utility;


	// was.openNewThread = openNewThread;

	if (window.webapp.system.webapp === 1) {
		window.webapp.system.webapp = 2;
		getNavigation();
	}

	function getNavigation() {
		let rc = true;
		// let url = document.location.href + '/Backend/webapp.php?module=System&task=Navigation';
		let url = '/Backend/webapp.php?module=System&task=Navigation';
		axios.get(url)
			.then(result => {
				// console.log(result.data);
				if (result.data['returncode'] === 1) {
					newPostingClass = result.data['content']['newPostingClass'];
					menuSource = result.data['content']['navigation'];
					// setMenu(makeMenu(menuSource));
					makeMenus(menuSource, 'main', 'Hauptmenü', '');
					rc = true;
				}
				else {
					// was.activateModule('login', false);
					wam.activate('login');
					rc = false;
				}
			})
		return rc;
	}

	function makeMenus(menuSource, id, title, parentId) {
		// console.log('makeMenu: ' + id);
		was.addNavigation(<Menu menuSource={menuSource} id={'menu-' + id} wa={props.wa} title={title} menuClick={menuClick} parentId={parentId} />);
		menuSource.forEach(
			function (item, index) {
				if (typeof item.children != 'undefined') {
					if (item.children.length > 0) {
						makeMenus(item.children, item.id, item.title, 'menu-' + id);
					}
				}
			}
		)
		// activateModule('forum');
	}


	function menuClick(item) {
		// event.preventDefault();
		//console.log(item);
		wan.hideAll();
		switch (item.module) {
			case 'submenu':
				was.activateNavigation(item.href);
				break;
			case 'function':
				was[item.href]();
				break;
			case 'extern':
				window.open(item.href);
				break;
			case 'logout':
				was.logout();
				break;
			default:
				wam.activate(item.module);
		}
	}

	function openNewThread() {
		const forumId = wam.getForumId();
		const wafId = forumId + '-new-thread';
		const newPostingShort = waf.get('new-thread-short', 'new-posting-short');

		const postingBackup = wau.postingRestore(wafId, 0);

		if (postingBackup === null || postingBackup.trim() === '') { 
			wau.postingBackup(wafId, 0, newPostingShort);
			waf.set(forumId + '-new-thread', 'posting', newPostingShort);
		}
		wam[forumId].showDialogNewThread();
		wam[forumId].setTab('postings'); // Funktion wird in tabs.js definiert
		wam.activate(forumId);
	}

	function activateHome() {
		wam.activate('forum');
		wam['forum'].scrollTop();
	}

	const unreadHint = (was.postingsUnread.forum > 0) ? " (" + was.postingsUnread['forum'] + ((was.postingsUnread.forum > 1) ? " ungelese Beiträge)" : " ungelesener Beitrag)") : '';
	return (
		<Block key="navigation-top" id="navigation-top " wa={props.wa} display="d-block" classes={`navigation navigation-top `} >
			<i id="home-icon" className={`bi ${(was.postingsUnread['forum'] > 0) ? 'bi-house-fill' : 'bi-house'} ' navigation-icon`} title={was.application + " anzeigen " + unreadHint} onClick={activateHome}>
				<span
					className={` ${was.postingsUnread['forum'] > 0 ? '' : 'd-none'} new-postings-icon-count `}
				>
					{(was.postingsUnread.forum > 99) ? "++" : was.postingsUnread['forum']}
				</span>

			</i>
			<i id="new-posting-icon" className="bi bi-chat-left-text navigation-icon" title="Eingabeformular öffnen" onClick={event => openNewThread(false)}></i>
			<NewThreadShort wa={props.wa} newPostingClass={newPostingClass} />
			<i className="bi bi-list navigation-icon" title="Navigation" onClick={e => was.activateNavigation('menu-main')}></i>
		</Block>


	);

}
