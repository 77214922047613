import React from 'react';
import Module from 'Components/Core/Module';
import MemberListBasis from './MemberListBasis';
import DialogAddMember from './DialogAddMember';

export default function MemberList(props) {

	const wa = props.wa;
	const wad = wa.dialog;
	const waf = wa.form;
	const wam = wa.module;
	const was = wa.system;
	const wafId = props.id;
	const dialogId = "dialog-add-member";

	wam.init(props.id, {
		"onClose": () => {
			waf.destroy(wafId);
			was.removeDialog(dialogId);
		}
	})

	waf.init(wafId, {});

	// Module Icons 
	const moduleToolbar = (was.memberLevel < 3)
		?
		<span className={` bi bi-person-plus module-icon  `} title="Neues Mitglied anlegen" onClick={() => addMember()}>
		</span>
		:
		null;

	function addMember() {
		wad['dialog-add-member'].show(() => { waf.focus(wafId, 'member-name') });
	}


	return (
		<Module 
			id={props.id} 
			wa={wa} 
			title={props.title} 
			display="d-block" 
			titleToolbar={
				<span>
					{moduleToolbar}
				</span>
			}
		>
			<MemberListBasis id="member-listing" wa={wa} title="" active={false} classes="listing " orderField="membername" />
			<DialogAddMember id={dialogId} wa={props.wa} wafId={wafId} />

		</Module>
	)


};

