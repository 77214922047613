import React from 'react';
import Module from 'Components/Core/Module';
import Form from 'Components/Core/Form/Form';
import FormElement from 'Components/Core/Form/FormElement';
import Avatar from 'Components/Member/Avatar';
import DialogSelectText from 'Components/Core/Form/DialogSelectText';
import DialogSetPassword from './DialogSetPassword';
import FormSelectIcon from 'Components/Core/Form/FormSelectIcon';
import 'CSS/Member/MemberProfile.css'

export default function MemberProfile(props) {

	const wa = props.wa;
	const wam = wa.module;
	const wad = wa.dialog;
	const waf = wa.form;
	const was = wa.system;
	const wau = wa.utility;
	const wafId = props.id;
	const buttons = [];
	const dialogId = "dialog-set-password";
	const wafIdDialog = wafId + '-dialog-set-password';
	const selectMemberLevelId = wafId + '-select-member-level';

	const [memberId, setMemberId] = React.useState(props.memberId);
	const [memberAvatarImage, setMemberAvatarImage] = React.useState('');
	const [memberLevel, setMemberLevel] = React.useState(props.memberLevel);
	const [memberName, setMemberName] = React.useState(props.memberName);
	const [confirmedClass, setConfirmedClass] = React.useState('d-none');
	const [lockClass, setLockClass] = React.useState('d-none');
	const [unlockClass, setUnlockClass] = React.useState('d-none');


	wam.init(props.id, {
		"title": 'Profil ',
		"onClose": () => {
			waf.destroy(wafId);
			waf.destroy(wafIdDialog);
			was.removeDialog(dialogId);
		}
	});

	waf.init(wafId, {
		"onAfterRead": onAfterRead
	});

	// Module Icons 

	if (was.memberLevel < 3) {
		buttons.push(<button type="button" key="confirm" className={`btn ${confirmedClass}`} onClick={confirm} >Anmeldung bestätigen</button>);
		buttons.push(<button type="button" key="lock" className={`btn ${lockClass}`} onClick={lock} >Sperren</button>);
		buttons.push(<button type="button" key="unlock" className={`btn ${unlockClass}`} onClick={unlock} >Freigeben</button>);
		buttons.push(<button type="button" key="set-password" className="btn " onClick={changePassword} >Kennwort setzen</button>);
	}

	function confirm() {
		was.axPost('Backend/webapp.php?module=Member&task=ConfirmMember', { "memberid": memberId }, (result) => {
			if (result.data.returncode === 1) {
				waf.set(wafId, 'confirmed', 'Ja', true);
				setConfirmedClass('d-none');
			}
		});
	}

	function lock() {
		was.axPost('Backend/webapp.php?module=Member&task=LockMember', { "memberid": memberId }, (result) => {
			if (result.data.returncode === 1) {
				waf.set(wafId, 'locked', 'Ja', true);
				setLockClass('d-none');
				setUnlockClass('flex');
			}
		});
	}

	function unlock() {
		was.axPost('Backend/webapp.php?module=Member&task=UnlockMember', { "memberid": memberId }, (result) => {
			if (result.data.returncode === 1) {
				waf.set(wafId, 'locked', 'Nein', true);
				setLockClass('flex');
				setUnlockClass('d-none');
			}
		});
	}

	function saveMemberLevel(memberLevel) {
		was.axPost('Backend/webapp.php?module=Member&task=SaveMemberLevel', { "memberId": memberId, "memberLevel": memberLevel }, (result) => {
			if (result.data.returncode === 1) {
				setMemberLevel(memberLevel);
				waf.set(wafId, 'memberLevel', was.memberLevelLabel[memberLevel], true);
			}
		});
	}

	function changePassword() {
		wad[dialogId].show(() => { waf.focus(wafIdDialog, 'new-password') });
	}

	function onAfterRead(data) {
		setMemberId(data.content.memberid);
		setMemberName(data.content.membername);
		setMemberLevel(data.content.memberlevel);
		setMemberAvatarImage(data.content.avatarimage);
		waf.set(wafId, 'memberId', data.content.memberid, true);
		waf.set(wafId, 'memberLevel', was.memberLevelLabel[data.content.memberlevel], true);
		waf.set(wafId, 'memberName', data.content.membername, true);
		waf.set(wafId, 'confirmed', (data.content.confirmed === 1) ? 'Ja' : 'Nein', true);
		waf.set(wafId, 'locked', (data.content.locked === 1) ? 'Ja' : 'Nein', true);
		waf.set(wafId, 'created', data.content.created, true);
		waf.set(wafId, 'postingCount', data.content.postingcount, true);
		waf.set(wafId, 'lastLogin', data.content.lastlogin, true);
		waf.set(wafId, 'lastRequest', data.content.lastrequest, true);
		if (data.content.confirmed === 0) {
			setConfirmedClass('flex');
		}
		else {
			setConfirmedClass('d-none');
		}
		if (data.content.locked === 0) {
			setLockClass('flex');
			setUnlockClass('d-none');
		}
		else {
			setLockClass('d-none');
			setUnlockClass('flex');
		}
		wam.init(props.id, {
			"title": 'Profil: ' + data.content.membername,
		});
	}

	const MemberLevelChangeTag = (was.memberLevel === 1) 
		? 
			<FormSelectIcon
				id={selectMemberLevelId}
				wafId={selectMemberLevelId}
				componentId={wau.getComponentId()}
				moduleId={props.id}
				wa={props.wa}
				classes="bi bi-pencil module-icon"
				default=""
				defaultKey={0}
				title='Status'
				hiddenInput={true}
				inputIcon='bi-pencil'
				onSelect={(key, value) => {
					saveMemberLevel(key);
				}}
			/>
		:
			null;		

	return (

		<Module id={props.id} wa={props.wa} title={props.title} display={props.display} displayTitle="d-block" classes="module-member-profile" >
			<div id="" className={` member-profile `}>
				<div key="avatar" className='d-flex'>
					<Avatar memberLevel={memberLevel} avatarText={memberName[0]} image={memberAvatarImage} size="xlarge" />
				</div>
				<Form wa={props.wa}
					wafId={wafId}
					module="Member"
					readTask="ReadMemberProfile"
					sendTask=""
					addUrl={'memberId=' + memberId}
					focusElement="name"
					classes="member-form"
					sendButton={false}
					buttons={buttons}>
					<fieldset className="form-group mb-2">
						<legend className="float-none w-auto">Allgemein</legend>
						<FormElement wa={props.wa} wafId={wafId} disabled={true} id="memberId" title="Mitglied Nr.:" />
						<FormElement wa={props.wa} wafId={wafId} disabled={true} id="memberName" title="Pseudonym:" />
						<FormElement wa={props.wa} wafId={wafId} disabled={true} id="memberLevel" title="Status:">
							{MemberLevelChangeTag}
						</FormElement>
						<FormElement wa={props.wa} wafId={wafId} id="confirmed" disabled={true} title="Bestätigt" />
						<FormElement wa={props.wa} wafId={wafId} id="locked" disabled={true} title="Gesperrt" />
					</fieldset>

					<fieldset key="statistic" className="mb-2">
						<legend>Statistik</legend>
						<FormElement wa={props.wa} wafId={wafId} disabled={true} id="created" title="Konto erstellt am:" />
						<FormElement wa={props.wa} wafId={wafId} disabled={true} id="postingCount" title="Postings:" />
						<FormElement wa={props.wa} wafId={wafId} disabled={true} id="lastLogin" title="Letzte Anmeldung:" />
						<FormElement wa={props.wa} wafId={wafId} disabled={true} id="lastRequest" title="Letzter Zugriff:" />
					</fieldset>
				</Form>
			</div>
			<DialogSetPassword id={dialogId} memberId={memberId} wa={props.wa} wafId={wafIdDialog} />
			<DialogSelectText 
				id={selectMemberLevelId} 
				moduleId={props.id} 
				wa={props.wa} 
				classes="dialog-select-member-level"
				data={
					[
						{'id': 1, 'name': was.memberLevelLabel[1]},
						{'id': 2, 'name': was.memberLevelLabel[2]},
						{'id': 3, 'name': was.memberLevelLabel[3]},
						{'id': 4, 'name': was.memberLevelLabel[4]}
					]} 
				title="Status" 
			/>

		</Module>

	)
};
